<template>
  <g fill="none" fill-rule="evenodd">
    <g>
      <g>
        <g>
          <path
            fill="#FFD05D"
            d="M0 16.948C0 7.588 7.588 0 16.948 0s16.947 7.588 16.947 16.948-7.588 16.947-16.947 16.947C7.588 33.895 0 26.307 0 16.948"
            transform="translate(-497 -1699) translate(367 1691) translate(130.073 8.108)"
          />
          <path
            fill="#FDC453"
            d="M25.814 2.483c1.688 2.634 2.666 5.767 2.666 9.128 0 9.36-7.587 16.948-16.947 16.948-3.25 0-6.287-.916-8.867-2.503 3.012 4.702 8.283 7.82 14.282 7.82 9.36 0 16.947-7.589 16.947-16.948 0-6.11-3.233-11.463-8.08-14.445"
            transform="translate(-497 -1699) translate(367 1691) translate(130.073 8.108)"
          />
          <path
            fill="#FFD77F"
            d="M2.303 18.735c0-8.69 7.045-15.734 15.735-15.734 5.08 0 9.596 2.407 12.473 6.143C27.794 4.427 22.7 1.25 16.867 1.25c-8.69 0-15.735 7.045-15.735 15.735 0 3.61 1.217 6.936 3.262 9.592-1.33-2.309-2.09-4.987-2.09-7.843"
            transform="translate(-497 -1699) translate(367 1691) translate(130.073 8.108)"
          />
          <path
            fill="#303030"
            d="M11.952 12.836c0 1.162-.559 2.105-1.248 2.105-.69 0-1.249-.943-1.249-2.105 0-1.163.56-2.105 1.249-2.105.69 0 1.248.942 1.248 2.105M24.44 12.836c0 1.162-.56 2.105-1.249 2.105-.69 0-1.249-.943-1.249-2.105 0-1.163.56-2.105 1.25-2.105.689 0 1.248.942 1.248 2.105"
            transform="translate(-497 -1699) translate(367 1691) translate(130.073 8.108)"
          />
          <path
            fill="#695342"
            d="M20.008 21.702c0 2.865-1.37 5.188-3.06 5.188s-3.061-2.323-3.061-5.188c0-2.865 1.37-5.188 3.06-5.188s3.06 2.323 3.06 5.188"
            transform="translate(-497 -1699) translate(367 1691) translate(130.073 8.108)"
          />
          <path
            fill="#F06669"
            d="M19.53 24.483c-2.232-1.227-4.05-.531-5.101.165.552 1.354 1.474 2.242 2.519 2.242 1.086 0 2.04-.96 2.583-2.407"
            transform="translate(-497 -1699) translate(367 1691) translate(130.073 8.108)"
          />
        </g>
      </g>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
